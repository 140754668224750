import { PropsWithChildren } from "react";
import { useUserAgreements } from "../../hooks/useUserAgreements";
import { Footer } from "./Footer";
import { Header } from "./Header";
import { SessionEndingDialog } from "./SessionEndingDialog";

export const PageLayout = ({ children }: PropsWithChildren) => {
  useUserAgreements();
  return (
    <div id="page-container">
      <div id="content-wrap">
        <Header />
        {children}
      </div>
      {/* Disabling the auto logout for now */}
      {/* <SessionEndingDialog /> */}
      <Footer />
    </div>
  );
};
