import { useEffect, useState } from "react";
import { AppBar, AppBarSection, AppBarSpacer } from "@progress/kendo-react-layout";
import logoImg from "../../assets/logo-310x79px.png";
import { NavMenu } from "./NavMenu";
import { Notifications } from "./Notifications";
import notificationApi from "../../api/notifications";
import { useApi } from "../../hooks/useApi";
import useTimer from "../../hooks/useTimer";

export const Header = () => {
  // Get the current subdomain from window.location.hostname
  const hostnameParts = window.location.hostname.split(".");
  const subdomain = hostnameParts.length > 2 ? hostnameParts[0] : "";

  // Check if the subdomain is "test" or if it's localhost
  const isTestSubdomain = subdomain === "test";
  const isLocalhost = window.location.hostname === "localhost";

  const imageUrl = `https://azurewebsitetemplates.blob.core.windows.net/${subdomain}/logo_gs.png`;

  const [imageExists, setImageExists] = useState(true);

  const handleImageError = () => {
    setImageExists(false);
  };

  const timerValue = 60; //seconds
  const getActiveApi = useApi(notificationApi.getActive);
  const [notificationItems, setNotificationItems] = useState<any[]>([]);
  const { count, restartTimer } = useTimer(0);

  useEffect(() => {
    getActiveApi.request();
  }, []);

  useEffect(() => {
    if (getActiveApi.data) 
      setNotificationItems(getActiveApi.data.notifications);
  }, [getActiveApi.data]);

  useEffect(() => {
    if (count >= timerValue) {
      if (!getActiveApi.loading) getActiveApi.request();

      restartTimer();
    }
  }, [count]);

  return (
    <>
      <AppBar themeColor="inherit" positionMode="sticky" style={{ zIndex: 99 }}>
        <div style={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <AppBarSection>
              <a href="/">
                <img src={logoImg} alt="logo" height={60} />
              </a>
            </AppBarSection>

            {isTestSubdomain || isLocalhost ? (
              <div
                style={{
                  fontSize: "24px",
                  color: isTestSubdomain ? "red" : "darkgoldenrod",
                }}
              >
                {isTestSubdomain ? "TEST" : "DEV"}
              </div>
            ) : (
              <>
                {imageExists && (
                  <div style={{ display: "flex" }}>
                    <div
                      style={{
                        borderLeft: "solid 3px gray",
                        marginLeft: 5,
                        paddingLeft: 10,
                        height: 60,
                      }}
                    >
                      <div>BROUGHT TO YOU BY</div>
                      <div style={{ flexDirection: "column" }}>
                        <img
                          src={imageUrl}
                          onError={handleImageError}
                          alt="Image"
                          style={{ height: 40 }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}

            <AppBarSpacer />

            <div style={{ display: "flex", alignItems: "baseline" }}>
              <NavMenu />
            </div>
          </div>

          <div style={{ width: "100%", border: "none" }}>
            <Notifications items={notificationItems} />
          </div>
        </div>
      </AppBar>
    </>
  );
};
